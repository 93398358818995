import {mapMutations, mapActions, mapGetters} from "vuex";
import Vue from "vue";
import {dynamicHead} from "../../mixins/dynamic-head";
//sections


export default {
    name: "home",
    components: {},
    mixins: [dynamicHead],
    data() {
        return {
            mainSlider: [],
        }
    },
    created() {
        this.getPageContent('home').then(() => {
            this.setPageItem(this.pageContent)

            this.pageContent.sections.data.forEach(el => {
                if (el.type === 'banner-slider') {
                    this.mainSlider = el.banners
                }
            })
        })
        this.setRequestFlag(true)
    },
    mounted() {
        if (this.checkStatus()) {
            this.closeThanksPopup(true)
        }
    },
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getPageContent: 'setting/GET_PAGE_CONTENT',
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            closeThanksPopup: 'order/SHOW_SUCCESS_PAYMENT_POPUP',
        }),
        checkStatus() {
            let showPopup = this.$route.query['payment-popup']

            if (Array.isArray(showPopup)) {
                showPopup = showPopup[0];
            }

            return showPopup === 'show';
        }
    }
}
